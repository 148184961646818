import { Capacitor } from "@capacitor/core";
import { AppUpdate } from "@capawesome/capacitor-app-update";

const getCurrentAppVersion = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (Capacitor.getPlatform() === "android") {
    return result.currentVersionCode;
  } else {
    return result.currentVersionName;
  }
};

const getAvailableAppVersion = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (Capacitor.getPlatform() === "android") {
    return result.availableVersionCode;
  } else {
    return result.availableVersionName;
  }
};

export const hasUpdateAvailable = async () => {
  const currentVersion = await getCurrentAppVersion();
  const availableVersion = await getAvailableAppVersion();
  return currentVersion !== availableVersion;
};

export const openAppStore = async () => {
  await AppUpdate.openAppStore();
};
