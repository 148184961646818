import { MantineProvider, ColorSchemeScript } from "@mantine/core";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate,
} from "@remix-run/react";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "stream-chat-react/dist/css/v2/index.css";
import "./tailwind.css";
import "@mantine/tiptap/styles.css";
import theme from "./theme";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./lib/queryClient";
import { Toaster } from "sonner";
import NotificationsListener from "~/components/NotificationsListener";
import { Viewport } from "~/components/Viewport";
import { RevenueCatProvider } from "./lib/revenueCat";
import { useIsDesktop, useRouteBodyClasses } from "./lib/hooks";
import clsx from "clsx";
import { useEffect } from "react";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { TolgeeProvider } from "@tolgee/react";
import { withSentry } from "@sentry/remix";
import { tolgee } from "./lib/tolgee";
import { hasUpdateAvailable, openAppStore } from "./lib/appVersion";

export function Layout({ children }: { children: React.ReactNode }) {
  const isDesktop = useIsDesktop();
  const bodyClasses = useRouteBodyClasses();

  return (
    <html lang={tolgee.getLanguage() ?? "en"}>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, initial-scale=1.0, viewport-fit=cover, width=device-width"
        />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body
        className={clsx("font-sans antialiased", !isDesktop ? bodyClasses : [])}
      >
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  useListenBackButton();
  useAppUrlListener();
  useAppActivityResumedListener();

  return (
    <TolgeeProvider tolgee={tolgee}>
      <MantineProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RevenueCatProvider>
            <Toaster position="top-center" />
            <Viewport>
              <Outlet />
            </Viewport>
            <NotificationsListener />
          </RevenueCatProvider>
        </QueryClientProvider>
      </MantineProvider>
    </TolgeeProvider>
  );
}

export default withSentry(App, {
  wrapWithErrorBoundary: true,
});

export function HydrateFallback() {
  return <></>;
}

function useListenBackButton() {
  const navigate = useNavigate();
  useEffect(() => {
    if (Capacitor.getPlatform() !== "android") {
      return;
    }

    const listener = CapacitorApp.addListener(
      "backButton",
      function ({ canGoBack }) {
        if (!canGoBack || window.history.state.idx === 1) {
          CapacitorApp.exitApp();
          return;
        }

        navigate(-1);
      },
    );

    return () => {
      listener.then((listener) => listener.remove());
    };
  }, [navigate]);
}

/**
 * Redirect the app to the correct route after it is opened by a deep link.
 */
function useAppUrlListener() {
  const navigate = useNavigate();

  useEffect(() => {
    const listener = CapacitorApp.addListener("appUrlOpen", function ({ url }) {
      const path = new URL(url).pathname;
      const searchParams = new URL(url).searchParams;

      if (path) {
        if (searchParams.has("next") && localStorage.getItem("token")) {
          navigate(searchParams.get("next")!);
        } else {
          navigate(path);
        }
      }
    });

    return () => {
      listener.then((listener) => listener.remove());
    };
  }, [navigate]);
}

function useAppActivityResumedListener() {
  useEffect(() => {
    const listener = CapacitorApp.addListener("resume", async () => {
      if (!Capacitor.isNativePlatform() || !import.meta.env.PROD) {
        return;
      }

      if (await hasUpdateAvailable()) {
        alert(
          tolgee.t(
            "app_update_available",
            "An update is available. Please update the app to continue using it.",
          ),
        );
        openAppStore();
      }
    });

    return () => {
      listener.then((listener) => listener.remove());
    };
  }, []);
}
